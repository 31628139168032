/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 17번째 로그에서는 제로 레이팅, 팟빵과 팟캐스트에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=CCOvWtpjWlQ"
  }, "오늘밤 김제동 - 83회 풀영상 2019.02.14 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hanbit.co.kr/rent/facilities.html"
  }, "한빛출판네트워크 | 대관 서비스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.amazon.co.jp/SONY-%E3%82%B9%E3%83%86%E3%83%AC%E3%82%AAIC%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%80%E3%83%BC-FM%E3%83%81%E3%83%A5%E3%83%BC%E3%83%8A%E3%83%BC%E4%BB%98-%E3%82%B7%E3%83%AB%E3%83%90%E3%83%BC-ICD-UX544F/dp/B00FMM6QKY"
  }, "ICD-UX544F Digital Voice Recorder - Amazon.co.jp")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sony.com/en-ma/electronics/voice-recorders/icd-tx650"
  }, "TX650 Digital Voice Recorder TX Series | ICD-TX650 | Sony MA")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bluedesigns.com/products/yeti/"
  }, "Blue - Yeti")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://itunes.apple.com/jp/podcast/rebuild/id603013428?mt=2"
  }, "Apple Podcat内のTatsuhiko Miyagawa 「Rebuild」")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://m.store.naver.com/places/detail?id=1949301700&source=blog&back=false"
  }, "트라이앵글 스터디카페 : 네이버")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/encrypted-sni-ko/"
  }, "암호화하지 않으면 무용지물​: 암호화된 SNI의 동작​")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www1.president.go.kr/petitions/522031"
  }, "https 차단 정책에 대한 반대 의견 > 국민청원 > 대한민국 청와대")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.mk.co.kr/newsRead.php?sc=30000018&year=2016&no=323995"
  }, "MK News - 테러리스트들은 텔레그램·지메일을 '좋아해'…교신할 때 애용")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://opennet.or.kr/15729"
  }, "정부의 SNI 필드 차단 기술 도입을 우려한다. | Open Net")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://headlines.yahoo.co.jp/hl?a=20190219-00000009-asahi-bus_all"
  }, "(일본어) 스마트폰의 특정 사이느 무제한 관람, 일부 규제하기로(총무성) - 아사히 신문")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://opennet.or.kr/13979"
  }, "제로레이팅(스폰서 요금제) 10문 10답: 세상에 공짜 점심은 없다 | Open Net")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.asiae.co.kr/news/view.htm?idxno=2017081017270568271"
  }, "방통위, 제로레이팅 토대 마련…포털 규제도 도입(종합) - 아시아경제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.khan.co.kr/kh_news/khan_art_view.html?art_id=201806121138001"
  }, "미국 ‘망중립성 원칙’ 오늘부터 폐지···구글 ‘을’ 될까? - 경향신문")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://byline.network/2018/05/16-15/"
  }, "전길남 vs 빈트서프, 인터넷 아버지들의 미묘한 시각차 - Byline Network")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bloomberg.com/news/articles/2018-11-16/google-s-solution-for-african-internet-balloons"
  }, "Google’s Solution for African Internet: Balloons - Bloomberg")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://fiber.google.com/about/"
  }, "Google Fiber | High Speed Internet Service & TV")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gizmodo.com/facebook-knows-how-to-track-you-using-the-dust-on-your-1821030620"
  }, "Facebook Knows How to Track You Using the Dust on Your Camera Lens")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://money.cnn.com/2018/06/27/technology/facebook-aquila-drone-abandon/index.html"
  }, "Facebook Aquila drone: Company gives up on building aircraft to beam internet")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.imbc.com/broad/radio/fm/economy/index.html"
  }, "이진우의 손에 잡히는 경제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.podbbang.com/ch/15781"
  }, "팟캐스트 김동환 이진우 정영진의 신과함께 방송듣기, 경제 : 팟빵")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://overcast.fm/"
  }, "Overcast")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://supertop.co/castro/"
  }, "Castro Podcasts")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.podbbang.com/"
  }, "국내 최대 팟캐스트 방송 : 오디오천국 팟빵")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.podty.me/"
  }, "팟티")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://namu.wiki/w/%ED%8C%9F%EB%B9%B5"
  }, "팟빵 - 나무위키")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://itunes.apple.com/us/app/castro-podcasts/id1080840241?mt=8"
  }, "Castro Podcasts on the App Store")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://variety.com/2019/digital/news/spotify-acquire-paid-gimlet-anchor-340-million-1203140881/"
  }, "Spotify Paid Nearly $340 Million for Gimlet Media and Anchor – Variety")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.zdnet.co.kr/view/?no=20190219072037"
  }, "마블-넷플릭스, 결별한다 - ZDNet korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.zdnet.co.kr/view/?no=20181111112157"
  }, "디즈니 \"새 스트리밍 서비스는 디즈니+\" - ZDNet korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://tv.naver.com/"
  }, "네이버TV")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://feedly.com/i/welcome"
  }, "Welcome to Feedly")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://lifehacker.com/google-reader-is-shutting-down-here-are-the-best-alter-5990456"
  }, "Google Reader Is Shutting Down; Here Are the Best Alternatives")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/@nikitonsky/medium-is-a-poor-choice-for-blogging-bb0048d19133"
  }, "Medium is a poor choice for blogging – Nikitonsky – Medium")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://qiita.com/"
  }, "Qiita")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://qiita.com/qiitadaisuki/items/2160a390ce91283707a1"
  }, "(일본어) 모든 개발자가 Qiita에 아웃풋을 그만해야하는 이유")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://support.google.com/webmasters/answer/139066?hl=en"
  }, "Consolidate duplicate URLs - Search Console Help")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
